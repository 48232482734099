import React, { useState } from "react";
import "./style.scss";
import CasesBlockNew from "../../caseBlock/CasesBlockNew";
import { Link } from "react-router-dom";

const data = {
  en: {
    title: "Our projects",
    textBtn: "To projects",
    linkBtn: "/portfolio/en",
    projects: [
      {
        id: "avisk",
        lang: "en",
        image: "/img/avisk-project.webp",
        title: "AVISK",
        text: "In the case, we talk about the development of product accounting software that helps restaurants and catering enterprises optimize product accounting, minimize losses and increase the efficiency of semi-finished products production.",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#django",
            url: "django",
          },
          {
            name: "#reactjs",
            url: "react",
          },
          {
            name: "#automation",
            url: "automation",
          },
        ],
      },
      {
        id: "giveawayapp",
        lang: "en",
        image: "/img/giveaway-project.webp",
        title: "Giveaway App",
        text: "A case study on how we developed a fintech application for conducting secure financial transactions in the Nigerian market, and then throughout Africa.",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#django",
            url: "django",
          },
          {
            name: "#flutter",
            url: "flutter",
          },
          {
            name: "#mobile",
            url: "mobile",
          },
          {
            name: "#crossplatform",
            url: "crossplatform",
          },
        ],
      },
      {
        id: "ai",
        lang: "en",
        image: "/img/ai-project.webp",
        title: "Artificial intelligence",
        text: "In this case, we will tell you how we developed artificial intelligence for recognizing dishes, which reduced the working time of waiters by 6.5 times when serving dishes on the Swedish line of the hotel.",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#PyTorch",
            url: "pytorch",
          },
          {
            name: "#OpenCV",
            url: "opencv",
          },
          {
            name: "#AI",
            url: "ai",
          },
        ],
      },
      {
        id: "yutstroi",
        lang: "en",
        image: "/img/citadel-project.webp",
        title: "YutStroi",
        text: "In the case, we will talk about the development of the YutStroi online store, which has 4 large hypermarkets in the Crimea, more than 180,000 items of goods and processes more than 800 orders per day.",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#django",
            url: "django",
          },
          {
            name: "#reactjs",
            url: "react",
          },
          {
            name: "#web",
            url: "web",
          },
        ],
      },
      {
        id: "vegatel",
        lang: "en",
        image: "/img/vegatel-project.webp",
        title: "Vegatel",
        text: "We are talking about the development of an application for measuring the cellular signal, which has become an additional sales channel and has gained more than 100 thousand downloads.",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#fastapi",
            url: "fastapi",
          },
          {
            name: "#mobile",
            url: "mobile",
          },
          {
            name: "#native",
            url: "native",
          },
        ],
      },
    ],
  },
  ru: {
    title: "Наши проекты",
    textBtn: "К проектам",
    linkBtn: "/portfolio/ru",
    projects: [
      {
        id: "avisk",
        lang: "ru",
        image: "/img/avisk-project.webp",
        title: "AVISK",
        text: "В кейсе рассказываем о разработке ПО для учета продуктов, которое помогает ресторанам и предприятиям общественного питания оптимизировать учет продуктов, минимизировать потери и повысить эффективность производства полуфабрикатов",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#django",
            url: "django",
          },
          {
            name: "#reactjs",
            url: "react",
          },
          {
            name: "#автоматизацияпроцессов",
            url: "automation",
          },
        ],
      },
      {
        id: "giveawayapp",
        lang: "ru",
        image: "/img/giveaway-project.webp",
        title: "Giveaway App",
        text: "Кейс о том, как мы разработали финтех приложение для проведения безопасных финансовых транзакций на рынке Нигерии, а потом и во всей Африке.",
        tagsCase: [
          {
            name: "#мобильнаяразработка",
            url: "mobile",
          },
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#кроссплатформеннаяразработка",
            url: "crossplatform",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#django",
            url: "django",
          },
          {
            name: "#flutter",
            url: "flutter",
          },
        ],
      },
      {
        id: "ai",
        lang: "ru",
        title: "Искусственный интеллект",
        image: "/img/ai-project.webp",
        text: "В данном кейсе мы расскажем, как разработали искусственный интеллект для распознавания блюд, который сократил время работы официантов в 6,5 раз при выдаче блюд на шведской линии отеля.",
        tagsCase: [
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#ИИ",
            url: "AI",
          },
          {
            name: "#PyTorch",
            url: "pytorch",
          },
          {
            name: "#OpenCV",
            url: "opencv",
          },
        ],
      },
      {
        id: "yutstroi",
        lang: "ru",
        image: "/img/citadel-project.webp",
        title: "УютСтрой",
        text: 'В кейсе расскажем о разработке интернет-магазина "УютСтрой", который имеет 4 крупных гипермаркета в Крыму, более 180000 наименований товаров и обрабатывает более 800 заказов в день.',
        tagsCase: [
          {
            name: "#вебразработка",
            url: "web",
          },
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#django",
            url: "django",
          },
          {
            name: "#reactjs",
            url: "react",
          },
        ],
      },
      {
        id: "vegatel",
        lang: "ru",
        image: "/img/vegatel-project.webp",
        title: "Vegatel",
        text: "Рассказываем о разработке приложения для измерения сигнала сотовой связи, которое стало дополнительным каналом продаж и набрало более 100 тыс. скачиваний.",
        tagsCase: [
          {
            name: "#мобильнаяразработка",
            url: "mobile",
          },
          {
            name: "#python",
            url: "python",
          },
          {
            name: "#agile",
            url: "agile",
          },
          {
            name: "#fastapi",
            url: "fastapi",
          },
          {
            name: "#нативнаяразработка",
            url: "native",
          },
        ],
      },
    ],
  },
};

export const ProjectsSection = ({ lang, page }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <section className="project-section">
      <div className="container">
        <div className="title">{data[lang].title}</div>
        <div className="list-portfolio">
          {data[lang].projects.map((e, i) => (
            <CasesBlockNew
              key={`${i}-${e.id}`}
              title={e?.title}
              idCase={e?.id}
              main={false}
              image={e?.image}
              text={e?.text}
              tagsCase={e?.tagsCase}
              lang={lang}
              page={page}
              currenttagsSecondLevel={null}
              onClick={() => {}}
            />
          ))}
        </div>
        <Link to={data[lang].linkBtn}>
          <div
            className="btn-action"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
          >
            {data[lang].textBtn}
            <img
              width={24}
              height={24}
              src={
                isHover
                  ? "/img/arrow-right-white.svg"
                  : "/img/arrow-right-orange.svg"
              }
              alt="arrow-to-projects"
              className="icon-arrow"
            />
          </div>
        </Link>
      </div>
    </section>
  );
};
