import React from "react";

export const ListMenu = ({ className, url, text }) => {
  return (
    <li>
      <div
        className={`link-sidebar-item ${className}`}
        onClick={() => document.getElementById(url)?.scrollIntoView()}
      >
        {text}
      </div>
    </li>
  );
};
