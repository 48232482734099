import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const data = {
  ru: {
    titleServices: "Услуги",
    titleIndustries: "Отрасли",
    cardsServices: [
      {
        title: "Мобильные приложения",
        img: "/img/mobile-icon.svg",
        link: "/portfolio/ru/?tagcase=crossplatform",
      },
      {
        title: "Веб-сайты",
        img: "/img/websites-icon.svg",
        link: "/portfolio/ru/?tagcase=web",
      },
      {
        title: "Автоматизация процессов",
        img: "/img/automation-icon.svg",
        link: "/portfolio/ru/?tagcase=automation",
      },
      {
        title: "Искусственный интеллект",
        img: "/img/ai-icon.svg",
        link: "/portfolio/ru/?tagcase=AI",
      },
      {
        title: "Highload решения",
        img: "/img/highload-icon.svg",
        link: "/portfolio/case/yutstroi/ru",
      },
      {
        title: "Разработка на Flutter",
        img: "/img/flutter-icon.svg",
        link: "/portfolio/ru/?tagcase=flutter",
      },
    ],
    cardsIndustries: [
      {
        title: "Фудтех",
        link: "/portfolio/ru/?tag=restaurant",
        cases: [
          {
            img: "/img/bar-logo.svg",
            link: "/portfolio/case/barlondon/ru",
          },
          {
            img: "/img/fotobox-logo.svg",
            link: "/portfolio/case/ai/ru",
          },
        ],
      },
      {
        title: "Стартапы",
        link: "/portfolio/ru/?tag=startup",
        cases: [
          {
            img: "/img/yoloprice-logo.svg",
            link: "/portfolio/case/yoloprice/ru",
          },
          {
            img: "/img/vegatel-logo.svg",
            link: "/portfolio/case/vegatel/ru",
          },
        ],
      },
      {
        title: "Финтех",
        link: "/portfolio/ru/?tag=fintech",
        cases: [
          {
            img: "/img/give-away-logo.svg",
            link: "/portfolio/case/giveawayapp/ru",
          },
          {
            img: "/img/fintech-logo.svg",
            link: "/portfolio/case/intecreservesautomation/ru",
          },
        ],
      },
      {
        title: "E-commerce",
        link: "/portfolio/case/yutstroi/ru",
        cases: [
          {
            img: "/img/bird-logo.svg",
            link: "/portfolio/case/zharptica/ru",
          },
          {
            img: "/img/citadel-logo.svg",
            link: "/portfolio/case/yutstroi/ru",
          },
        ],
      },
    ],
  },
  en: {
    titleServices: "Services",
    titleIndustries: "Industries",
    cardsServices: [
      {
        title: "Mobile App Development",
        img: "/img/mobile-icon.svg",
        link: "/portfolio/en/?tagcase=crossplatform",
      },
      {
        title: "Website Development",
        img: "/img/websites-icon.svg",
        link: "/portfolio/en/?tagcase=web",
      },
      {
        title: "Process Automation",
        img: "/img/automation-icon.svg",
        link: "/portfolio/en/?tagcase=automation",
      },
      {
        title: "Artificial Intelligence (AI) Solutions",
        img: "/img/ai-icon.svg",
        link: "/portfolio/en/?tagcase=AI",
      },
      {
        title: "Highload System Development",
        img: "/img/highload-icon.svg",
        link: "/portfolio/case/yutstroi/en",
      },
      {
        title: "Flutter App Development",
        img: "/img/flutter-icon.svg",
        link: "/portfolio/en/?tagcase=flutter",
      },
    ],
    cardsIndustries: [
      {
        title: "FoodTech",
        link: "/portfolio/en/?tag=restaurant",
        cases: [
          {
            img: "/img/bar-logo.svg",
            link: "/portfolio/case/barlondon/en",
          },
          {
            img: "/img/fotobox-logo.svg",
            link: "/portfolio/case/ai/en",
          },
        ],
      },
      {
        title: "Startups",
        link: "/portfolio/en/?tag=startup",
        cases: [
          {
            img: "/img/yoloprice-logo.svg",
            link: "/portfolio/case/yoloprice/en",
          },
          {
            img: "/img/vegatel-logo.svg",
            link: "/portfolio/case/vegatel/en",
          },
        ],
      },
      {
        title: "FinTech",
        link: "/portfolio/en/?tag=fintech",
        cases: [
          {
            img: "/img/give-away-logo.svg",
            link: "/portfolio/case/giveawayapp/en",
          },
          {
            img: "/img/fintech-logo.svg",
            link: "/portfolio/case/intecreservesautomation/en",
          },
        ],
      },
      {
        title: "E-commerce",
        link: "/portfolio/case/yutstroi/en",
        cases: [
          {
            img: "/img/bird-logo.svg",
            link: "/portfolio/case/zharptica/en",
          },
          {
            img: "/img/citadel-logo.svg",
            link: "/portfolio/case/yutstroi/en",
          },
        ],
      },
    ],
  },
};

export const ServicesAndIndustriesSection = ({ lang }) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth);
    }
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <section className="services-wrapper">
      <div className="services-block">
        <div className="block-wrapper">
          <div className="title">{data[lang].titleServices}</div>
          <div className="cards-wrapper">
            {data[lang].cardsServices.map((e, i) => (
              <Link to={e.link} key={`services-item-${i}-${e.title}`}>
                <div className="card-item">
                  <img className="item-img" src={e.img} alt="icon" />
                  <p
                    className={`item-text ${
                      (i === 1 && lang === "ru") ||
                      (i === 2 &&
                        lang === "en" &&
                        (width > 991 || width < 747)) ||
                      ((i === 4 || i === 5) && lang === "en" && width < 992)
                        ? "text__bottom"
                        : ""
                    }`}
                  >
                    {e.title}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="industries-block">
        <div className="block-wrapper">
          <div className="title">{data[lang].titleIndustries}</div>
          <div className="cards-wrapper">
            {data[lang].cardsIndustries.map((e, i) => (
              <div
                className="card-item"
                key={`industries-item-${i}-${e.title}`}
              >
                <div className="item-cases">
                  {e.cases.map((el, ind) => (
                    <Link to={el.link} key={`case-img-${ind}`}>
                      <img className="item-img" src={el.img} alt="icon" />
                    </Link>
                  ))}
                </div>
                <Link to={e.link}>
                  <p className="item-text">{e.title}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
