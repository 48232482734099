import React, { useMemo, useState, useEffect } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import {
  BaseModal,
  Checkbox,
  InputField,
  Spinner,
  UploadFile,
} from "../../../ui";
import { sendContacts } from "../../../tools/sendContacts";
import { validateFieldPhone, validateFieldText } from "../../../ui/InputField";

export const data = {
  en: {
    heading: "Consultation",
    subHeading:
      "Our consultation will help you answer the following questions:",
    headingListIcon: "/img/consultation-list-icon.svg",
    headingList: [
      "How to create a proper technical specification (understanding application architecture)",
      "What is the cost of your app (understanding price ranges)",
      "How to reduce app development costs without compromising quality (cost optimization recommendations)",
      "How to build an effective and results-driven app (recommendations for enhancing your app)",
    ],
    submitText: "Leave a request",
    formTitle:
      "Leave a request, and our experts will guide you through the process.",
    placeholderPhone: "Please enter your phone number",
    name: "Name",
    phone: "Phone",
    message: "Message",
    agree: "Non Disclosure Agreement",
    privacy:
      "I agree with the privacy policy and the use of the requested data",
    sendText: "We have received your message",
    sendError: "An error has occurred! Try later.",
    button: "Send",
    errorName: "Name must not contain numbers",
    errorPhone: "The phone number was entered incorrectly",
    errorBudget: "Choose a budget",
    errPrivacy: "Set this checkbox",
    necessarily: "Please fill in this field",
    upload: "Choose File",
    notFile: "File not selected",
    limitation: "The volume of uploaded files should not exceed 10Mb",
  },
  ru: {
    heading: "Консультация",
    subHeading: "Вам это поможет ответить на следующие вопросы:",
    headingListIcon: "/img/consultation-list-icon.svg",
    headingList: [
      "Как правильно сделать техническое задание (понимание архитектуры приложения)",
      "Сколько стоит ваше приложение (понимание вилки цен)",
      "Как сделать приложение дешевле, не теряя в качестве? (рекомендации по оптимизации затрат)",
      "Как сделать эффективное и приносящее результат приложение (рекомендации по «усилению» приложения)",
    ],
    submitText: "Оставить заявку",
    formTitle: "Оставьте заявку и наши специалисты проконсультируют вас",
    placeholderPhone: "Укажите номер телефона",
    name: "Имя",
    phone: "Телефон",
    message: "Сообщение",
    sendText: "Ваша заявка принята",
    sendError: "Произошла ошибка! Попробуйте позже.",
    button: "Отправить заявку",
    errorName: "Имя должно содержать только буквы и пробелы",
    errorBudget: "Выберите бюджет",
    errPrivacy: "Установите этот флажок",
    upload: "Выбрать файл",
    notFile: "Файл не выбран",
    limitation: "Объём загружаемых файлов не должен превышать 10Мб",
    errorPhone: "Номер телефона введен не верно",
    necessarily: "Заполните это поле",
    agree: "Согласие о неразглашении (NDA)",
    privacy:
      "Я согласен c политикой конфиденциальности и использования запрашиваемых данных",
  },
};

const validateForm = (form, setForm, lang) => {
  console.log(data[lang].errMsgPhone);
  setForm({
    ...form,
    errMsgName: validateFieldText(
      form.name,
      form.name ? data[lang].errorName : data[lang].necessarily
    ),
    errMsgPhone: validateFieldPhone(
      form.phone,
      form.phone ? data[lang].errorPhone : data[lang].necessarily
    ),
    errMsgBudget: form.budget ? "" : data[lang].errorBudget,
    errMsgPrivacy: form.privacy ? "" : data[lang].errPrivacy,
  });
};

const initStateForm = {
  name: "",
  phone: "",
  message: "",
  isSending: false,
  errMsgName: "",
  errMsgPhone: "",
  errMsgBudget: "",
  errMsgPrivacy: "",
  privacy: false,
  nda: false,
  file: "",
  fileName: "",
  budget: "",
};

export const ConsultationSection = ({ lang, page, nameCase }) => {
  const history = useHistory();
  const [form, setForm] = useState(initStateForm);
  const [isShowModal, setIsShowModal] = useState(false);
  const [width, setWidth] = useState(0);
  const isMobile = useMemo(
    () => (width && width < 991 ? true : false),
    [width]
  );

  const isValidate = useMemo(
    () =>
      form.name &&
      form.phone &&
      form.privacy &&
      !form.isSending &&
      !form.errMsgPhone &&
      !form.errMsgName,
    [
      form.name,
      form.phone,
      form.privacy,
      form.isSending,
      form.errMsgPhone,
      form.errMsgName,
    ]
  );

  const redirectToNotificationPage = () =>
    history.push(`/notification/${lang}`);

  const hiddenShowModal = () => setIsShowModal(!isShowModal);

  const submitData = async (e) => {
    e.preventDefault();
    if (!isValidate) {
      validateForm(form, setForm, lang);
      return;
    }
    setForm({ ...form, isSending: true });
    const { name, phone, message, file, budget, fileName, privacy, nda } = form;

    await sendContacts(
      {
        name,
        phone,
        message,
        budget,
        file,
        fileName,
        tag: `финальная форма ${
          page === "case" ? nameCase : page === "general" ? "mobile" : page
        }`,
        privacy,
        nda,
      },
      redirectToNotificationPage,
      hiddenShowModal
    );

    setForm(initStateForm);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth);
    }
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <section className="consultation-section" id="contacts">
      <div className="container">
        <div className="title">{data[lang].heading}</div>
        <div className="subtitle-wrapper">
          <div className="subtitle">{data[lang].subHeading}</div>
          <div className="subtitle-list">
            {data[lang].headingList.map((e, i) => (
              <div className="list-item" key={`consultation-item-${i}`}>
                <img src={data[lang].headingListIcon} alt="item-icon" />
                <span className="item-text">{e}</span>
              </div>
            ))}
          </div>
        </div>
        <form className="form" onSubmit={submitData} noValidate>
          {!isMobile && (
            <img
              className="form-bg"
              src="/img/consultation-form.svg"
              alt="form-request-bg"
            />
          )}
          <div className="form-title">{data[lang].formTitle}</div>
          <div className="block-fields">
            <InputField
              value={form.phone}
              onChangeHandler={(val) => setForm({ ...form, phone: val })}
              required={true}
              placeholder={data[lang].phone}
              handlerOnBlur={(val) => setForm({ ...form, errMsgPhone: val })}
              type="tel"
              errorMessage={form.errMsgPhone}
              validateMessage={
                form.phone ? data[lang].errorPhone : data[lang].necessarily
              }
              ariaLabel="Your Phone"
              placeholderTwo={data[lang].placeholderPhone}
            />
            <InputField
              value={form.name}
              onChangeHandler={(val) => setForm({ ...form, name: val })}
              required={true}
              placeholder={data[lang].name}
              handlerOnBlur={(val) => setForm({ ...form, errMsgName: val })}
              errorMessage={form.errMsgName}
              validateMessage={
                form.name ? data[lang].errorName : data[lang].necessarily
              }
              ariaLabel="Your name"
            />
            <InputField
              value={form.message}
              onChangeHandler={(val) => setForm({ ...form, message: val })}
              placeholder={data[lang].message}
              type="text"
              isTextarea={true}
              ariaLabel="Your message"
            />
            <UploadFile
              textBtn={data[lang].upload}
              placeholder={data[lang].notFile}
              fileName={form.fileName}
              textInfo={data[lang].limitation}
              setFile={(file, fileName) => setForm({ ...form, file, fileName })}
            />
            <div className="checkboxes">
              <Checkbox
                label={data[lang].agree}
                isChecked={form.nda}
                onChange={(nda) => setForm({ ...form, nda })}
                name="nda"
              />
              <Checkbox
                name="privacy-police"
                label={data[lang].privacy}
                isChecked={form.privacy}
                onChange={(privacy) =>
                  setForm({
                    ...form,
                    errMsgPrivacy: privacy ? "" : data[lang].errPrivacy,
                    privacy,
                  })
                }
                errorMessage={form.errMsgPrivacy}
              />
            </div>
            <button className="btn-action" type="submit">
              {form.isSending ? <Spinner /> : <span>{data[lang].button}</span>}
            </button>
          </div>
        </form>
      </div>
      <BaseModal
        open={isShowModal}
        close={hiddenShowModal}
        msg={data[lang].sendError}
        img={"/img/error.svg"}
      />
    </section>
  );
};
