import React from "react";
import "./style.scss";

const data = {
  ru: {
    title: "Web и mobile разработка, автоматизация процессов",
    text: "Проводим аналитику, создаём цифровую экосистему и прописываем стратегию развития проекта. У нас зрелая команда с глубоким пониманием бизнес-процессов и ориентацией на задачи бизнеса.",
    btnText: "ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ",
    infoCards: [
      {
        title: "6+",
        text: "лет на рынке разработки",
      },
      {
        title: "50+",
        text: "проектов запущено в 8 странах",
      },
      {
        title: "70%",
        text: "клиентов возвращаются к нам с новыми идеями",
      },
      {
        title: "60+",
        text: "разработчиков уровня middle и senior",
      },
    ],
  },
  en: {
    title: "Web and Mobile Development, Process Automation",
    text: "We conduct analytics, create a digital ecosystem, and develop a project growth strategy. Our experienced team has a deep understanding of business processes and is focused on achieving business goals.",
    btnText: "GET A FREE CONSULTATION",
    infoCards: [
      {
        title: "6+",
        text: "years in the development market",
      },
      {
        title: "50+",
        text: "projects launched in 8 countries",
      },
      {
        title: "70%",
        text: "of clients return to us with new ideas",
      },
      {
        title: "60+",
        text: "middle and senior-level developers",
      },
    ],
  },
};

export const ServicesSection = ({ lang }) => {
  const scrollToConsultationBlock = () => {
    const consultationBlock = document.getElementById("contacts");
    const header = document.querySelector(".main-header");
    if (consultationBlock && header) {
      window.scrollTo({
        top: consultationBlock.offsetTop - header.clientHeight,
        behavior: "smooth",
      });
    }
  };
  return (
    <section className="bg-services">
      <div className="container">
        <div className="wrapper-content">
          <div className="title">{data[lang].title}</div>
          <div className="text">{data[lang].text}</div>
          <div className="btn-action" onClick={scrollToConsultationBlock}>
            {data[lang].btnText}
          </div>
          <div className="info-cards">
            {data[lang].infoCards.map((el, idx) => (
              <div className="card-item" key={`card-item-${idx}`}>
                <p className="item-title">{el.title}</p>
                <p className="item-text">{el.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
