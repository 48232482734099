import React, { Component } from "react";
import "./style.scss";
import { ContactCard } from "./ContactCard";
import { ContactItem } from "./ContactItem";
const initData = (lang) => (lang === "en" ? en : ru);

const en = {
  heading: "Always in touch",
  textSale: "Sales department: ",
  contact: "Auezov Street 14A, Almaty 050026, Kazakhstan",
  contactUrl: "https://goo.gl/maps/iun3DUc6uf4DaSzd9",
  phone: "+7 705 740 6005",
  phoneWhatsApp: "+7 705 740 6005",
  phoneUrlWhatsApp: "https://api.whatsapp.com/send/?phone=77057406005",
  phoneUrl: "tel:+77057406005",
  mail: "hello@itfox-web.com",
  textHR: "Employment questions: ",
  textauth: "Сooperation: ",
  telegramItFoxweb: "@ItFoxweb",
  hremail: "hr@itfox-web.com",
};
const ru = {
  heading: "Всегда на связи",
  textSale: "Отдел продаж: ",
  contact: "г. Сочи, ул. Пластунская, 80B",
  contactUrl: "https://goo.gl/maps/XU7F9xWmvZtEKyJk7",
  phone: "+7 (928) 854-24-62",
  phoneWhatsApp: "+7 (928) 854-24-62",
  phoneUrlWhatsApp: "https://api.whatsapp.com/send/?phone=79288542462",
  phoneUrl: "tel:+79288542462",
  mail: "hello@itfox-web.com",
  textHR: "HR отдел: ",
  textauth: "Сотрудничество: ",
  telegramItFoxweb: "@ItFoxweb",
  hremail: "hr@itfox-web.com",
};

class ContactBlockNew extends Component {
  state = {
    data: initData(this.props.lang),
  };

  render() {
    return (
      <section
        id="contact-block-new"
        className={`section py-3 ${
          this.props.page === "main" ? "contact-block" : ""
        }`}
      >
        <span id="contact"></span>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-12 col-md-12 col-lg-12">
              <h2>{this.state.data.heading}</h2>
            </div>
            <div className="contact-wrap-new col-12 text-center">
              <ContactCard text={this.state.data.textSale}>
                <ContactItem
                  url={this.state.data.phoneUrlWhatsApp}
                  img="/img/whatsapp-icon.svg"
                  alt="phone"
                  linkText={this.state.data.phoneWhatsApp}
                  ariaLabel="Our phone"
                />
                <ContactItem
                  url="https://t.me/ItFoxweb"
                  img="/img/TelegramLogo.svg"
                  alt="telegram"
                  ariaLabel="Our telegram"
                  linkText={this.state.data.telegramItFoxweb}
                />
                <ContactItem
                  url="mailto:hello@itfox-web.com"
                  img="/img/mail-01.svg"
                  alt="email"
                  ariaLabel="Our email"
                  linkText={this.state.data.mail}
                />
                <ContactItem
                  url={this.state.data.contactUrl}
                  img="/img/marker-pin-03.svg"
                  alt="phone"
                  ariaLabel="Our address"
                  linkText={this.state.data.contact}
                />
                <ContactItem
                  url={this.state.data.phoneUrl}
                  img="/img/Call.svg"
                  alt="phone"
                  ariaLabel="Our phone"
                  linkText={this.state.data.phone}
                />
              </ContactCard>
              <ContactCard text={this.state.data.textHR}>
                <ContactItem
                  url={this.state.data.phoneUrlWhatsApp}
                  img="/img/whatsapp-icon.svg"
                  alt="phone"
                  linkText={this.state.data.phoneWhatsApp}
                  ariaLabel="Our phone"
                />
                <ContactItem
                  url="https://t.me/ItFoxweb"
                  img="/img/TelegramLogo.svg"
                  alt="telegram"
                  ariaLabel="Our telegram"
                  linkText={this.state.data.telegramItFoxweb}
                />
                <ContactItem
                  url="mailto:hr@itfox-web.com"
                  img="/img/mail-01.svg"
                  alt="email"
                  ariaLabel="Our hr email"
                  linkText={this.state.data.hremail}
                />
              </ContactCard>
              <ContactCard text={this.state.data.textauth}>
                <ContactItem
                  url={this.state.data.phoneUrlWhatsApp}
                  img="/img/whatsapp-icon.svg"
                  alt="phone"
                  linkText={this.state.data.phoneWhatsApp}
                  ariaLabel="Our phone"
                />
                <ContactItem
                  url="mailto:hello@itfox-web.com"
                  img="/img/mail-01.svg"
                  alt="email"
                  ariaLabel="Our email"
                  linkText={this.state.data.mail}
                />
              </ContactCard>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactBlockNew;
