import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
export default class CasesBlockNew extends Component {
  render() {
    const { image, imageMobile, title, text, idCase, tagsCase, index, main } =
      this.props;
    return (
      <div
        className={`${
          this.props.page !== "main"
            ? `col-12 col-md-6 ${
                index < 2 && main === true ? "col-lg-6" : "col-lg-4"
              }`
            : "project-item-wrappper"
        }`}
      >
        <div className="project-item">
          <div className="project-item_img">
            <Link to={`/portfolio/case/${idCase}/${this.props.lang}`}>
              <img
                src={index < 2 && window.innerWidth < 768 ? imageMobile : image}
                alt="Проект Fintech"
              />
            </Link>
          </div>
          <div className="project-item_desc">
            <div className="ellipsis">
              <span className="project-item_title">
                <Link to={`/portfolio/case/${idCase}/${this.props.lang}`}>
                  {title}
                </Link>
              </span>
              <ul className="article-item_tags">
                {tagsCase?.map((tag, index) => {
                  return (
                    index < 3 && (
                      <Link
                        to={`/portfolio/${this.props.lang}/?tagcase=${tag?.url}`}
                        key={index}
                        onClick={() => this.props.onClick(tag)}
                      >
                        <span
                          className={`item-tag ${
                            this.props.currenttagsSecondLevel?.name ===
                            tag?.name
                              ? "active"
                              : ""
                          }`}
                        >
                          {tag.name}
                        </span>
                      </Link>
                    )
                  );
                })}
              </ul>
              <p>{text}</p>
            </div>
            <div className="setup_wrapper-btn">
              <Link
                to={`/portfolio/case/${idCase}/${this.props.lang}`}
                className="setup_item-btn"
              >
                {this.props.lang === "ru" ? "к проекту" : "to project"}
                <span className="ico-arrow">
                  <img
                    width={24}
                    height={24}
                    src="/img/arrow-down.svg"
                    alt=""
                  />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
