import React, { useMemo, useState, useRef } from "react";
import "./style.scss";

export const validateFieldPhone = (value, validateMessage) => {
  if (!value) {
    return validateMessage;
  }
  const regex = /^\+?[0-9\s\-()]{10,40}$/;
  return !!regex.test(value) ? "" : validateMessage;
};

export const validateFieldText = (value, validateMessage) => {
  if (!value.trim()) {
    return validateMessage;
  }
  if (value.length > 64) {
    return validateMessage;
  }
  const regex = /^[\p{Lu}\p{Ll}\s',-]+$/u;

  return !!regex.test(value) ? "" : validateMessage;
};

export const validateFieldEmail = (value, validateMessage) => {
  if (!value) {
    return validateMessage;
  }
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return !!regex.test(value) ? "" : validateMessage;
};

export const InputField = ({
  value,
  onChangeHandler,
  type = "text",
  required,
  handlerOnBlur,
  placeholder,
  errorMessage = "",
  validateMessage = "",
  isTextarea = false,
  ariaLabel,
  placeholderTwo,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);
  const validate = useMemo(() => {
    switch (type) {
      case "tel":
        return validateFieldPhone(value, validateMessage);
      case "email":
        return validateFieldEmail(value, validateMessage);
      default:
        return validateFieldText(value, validateMessage);
    }
  }, [value, type, validateMessage]);

  const onChange = (e) => {
    e.preventDefault();
    onChangeHandler(e.target.value);
  };

  const onBlur = () => {
    if (required && handlerOnBlur) {
      handlerOnBlur(validate);
      setIsFocus(false);
      console.log(handlerOnBlur(validate));
    }
  };

  const handlePlaceholderClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      className={`wrapper-field ${errorMessage && !isFocus ? "not-valid" : ""}`}
    >
      {isTextarea ? (
        <textarea
          value={value}
          onChange={onChange}
          className="field"
          type={type}
          onBlur={onBlur}
          ref={inputRef}
          onFocus={() => setIsFocus(true)}
          aria-label={ariaLabel}
        />
      ) : (
        <input
          value={value}
          onChange={onChange}
          className="field"
          type={type}
          onBlur={onBlur}
          onFocus={() => setIsFocus(true)}
          ref={inputRef}
          aria-label={ariaLabel}
        />
      )}
      {!value && (
        <label
          className={`label ${placeholderTwo ? "label-outside" : ""}`}
          onClick={handlePlaceholderClick}
        >
          <span>{placeholder}</span> <span>{!!required && "*"}</span>
        </label>
      )}
      {!!(errorMessage && !isFocus) && (
        <div className="error-message">{errorMessage}</div>
      )}
    </div>
  );
};
