import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";

const data = {
  ru: {
    title: "Награды и достижения",
    textBtn: "Показать все награды",
    textBtnHide: "Скрыть награды",
    infoCards: [
      {
        cardTitle: "Wadline",
        cardImg: "/img/award-1.svg",
        cardText: "Лучшая веб-студия в регионе",
      },
      {
        cardTitle: "Рейтинг Рунета",
        cardImg: "/img/award-13.svg",
        cardText: "Разработка мобильных приложений «под ключ» по России",
      },
      {
        cardTitle: "Рейтинг Рунета",
        cardImg: "/img/award-10.svg",
        cardText:
          "Разработка мобильных приложений в категории «Здоровье и Финтес»",
      },
      {
        cardTitle: "Рейтинг Рунета",
        cardImg: "/img/award-23.svg",
        cardText: "Разработка мобильных приложений В2С",
      },
      {
        cardTitle: "#DESIGNRUSH",
        cardImg: "/img/award-1.svg",
        cardText: "Лучший дизайн приложения для Android и iOS (Bar London)",
      },
      {
        cardTitle: "Wadline",
        cardImg: "/img/award-1.svg",
        cardText: "Конкурс Nevember Rain (Жар. Птица)",
      },
      {
        cardTitle: "Tagline Awards 2024",
        cardImg: "/img/award-2.svg",
        cardText: "Лучшее международное мобильное приложение (Giveaway App)",
      },
      {
        cardTitle: "Рейтинг Рунета",
        cardImg: "/img/award-1.svg",
        cardText: "Разработка сайтов в регионе",
      },
    ],
  },
  en: {
    title: "Awards and Recognition",
    textBtn: "Show all awards",
    textBtnHide: "Hide awards",
    infoCards: [
      {
        cardTitle: "Wadline",
        cardImg: "/img/award-1.svg",
        cardText: "Best Web Studio in the Region",
      },
      {
        cardTitle: "Runet Rating",
        cardImg: "/img/award-13.svg",
        cardText: "End-to-End Mobile App Development in Russia",
      },
      {
        cardTitle: "Runet Rating",
        cardImg: "/img/award-10.svg",
        cardText: "Mobile App Development in the 'Health & Fitness' Category",
      },
      {
        cardTitle: "Runet Rating",
        cardImg: "/img/award-23.svg",
        cardText: " B2C Mobile App Development",
      },
      {
        cardTitle: "#DESIGNRUSH",
        cardImg: "/img/award-1.svg",
        cardText: "Best App Design for Android and iOS (Bar London)",
      },
      {
        cardTitle: "Wadline",
        cardImg: "/img/award-1.svg",
        cardText: "November Rain Competition Award (Firebird)",
      },
      {
        cardTitle: "Tagline Awards 2024",
        cardImg: "/img/award-2.svg",
        cardText: "Best International Mobile App (Giveaway App)",
      },
      {
        cardTitle: "Runet Rating",
        cardImg: "/img/award-1.svg",
        cardText: "Website Development in the Region",
      },
    ],
  },
};

export const AwardsMainSection = ({ lang, text }) => {
  const [width, setWidth] = useState(0);
  const [isAllAwards, setIsAllAwards] = useState(true);
  const isMobile = useMemo(
    () => (width && width < 991 ? true : false),
    [width]
  );

  const showAllAwards = () => {
    setIsAllAwards((prev) => !prev);
    const tag = document.getElementById("awards-main");
    if (isAllAwards) {
      window.scrollTo({
        top: tag.offsetTop - 250,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    function handleWindowResize() {
      setWidth(window.innerWidth);
    }
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setIsAllAwards(false);
    } else {
      setIsAllAwards(true);
    }
  }, [isMobile]);

  return (
    <section id="awards-main">
      <div className="container">
        <div className="title">{data[lang].title}</div>
        <div className="wrapper-cards">
          {isAllAwards
            ? (text ? text : data)[lang].infoCards.map((e, i) => (
                <div className="card-item" key={`award-item-${i}`}>
                  <img src={e.cardImg} alt="icon" />
                  <div>
                    <p className="item-title">{e.cardTitle}</p>
                    <p className="item-text">{e.cardText}</p>
                  </div>
                </div>
              ))
            : (text ? text : data)[lang].infoCards.slice(0, 3).map((e, i) => (
                <div className="card-item" key={`award-item-${i}`}>
                  <img src={e.cardImg} alt="icon" />
                  <div>
                    <p className="item-title">{e.cardTitle}</p>
                    <p className="item-text">{e.cardText}</p>
                  </div>
                </div>
              ))}
        </div>
        {isMobile && (
          <div className="btn-action" onClick={showAllAwards}>
            {isAllAwards
              ? (text ? text : data)[lang].textBtnHide
              : (text ? text : data)[lang].textBtn}
          </div>
        )}
      </div>
    </section>
  );
};
